<template>
    <el-dialog width="600" v-bind:title="$t('Act.maintenance')" :visible="dialogMaintenanceVisible" @close="dialogMaintenanceOnClose()">
        <el-form ref="dataForm" :rules="rules" :model="updateQuery" size="small" style="margin-top: 10px;">
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="maintain" v-bind:label="$t('Maintenance')+$t('Date')" label-width="150px">
                        <el-date-picker id="maintain"
                            v-model="updateQuery.maintain"
                            size="small" style="width: 200px"
                            class="filter-item"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :placeholder="$t('Date')" >
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="title" v-bind:label="$t('Title')" label-width="150px">
                        <el-input id="title" size="small" v-model="updateQuery.title" :placeholder="$t('Input')+' '+$t('Title')" class="filter-item" clearable style="width:100%;" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="22" :lg="22" :xl="22">
                    <el-form-item prop="description" v-bind:label="$t('Description')" label-width="150px">
                        <el-input id="description" size="small" v-model="updateQuery.description" type="textarea" :placeholder="$t('Input')+' '+$t('Description')" class="filter-item"
                            clearable style="width:100%;" />
                    </el-form-item>
                </el-col>
            </el-row>
            <el-row :gutter="10">
                <el-col :xs="22" :sm="22" :md="11" :lg="11" :xl="11">
                    <el-form-item prop="dateRange" v-bind:label="$t('Publish')+$t('Date')" label-width="150px">
                        <el-date-picker
                            v-model="updateQuery.dateRange"
                            size="small"
                            class="filter-item"
                            type="daterange"
                            value-format="yyyy-MM-dd"
                            range-separator="-"
                            :start-placeholder="$t('Start')+$t('Date')"
                            :end-placeholder="$t('End')+$t('Date')">
                        </el-date-picker>
                    </el-form-item>
                </el-col>
            </el-row>
        </el-form>
        <el-button style="margin-bottom: 0px; margin-top: 10px;" size="median" type="primary" @click="updateMaintenance()">{{$t('Save')}}</el-button>
    </el-dialog>
</template>

<script>
import { mixins } from '@/views/common/mixins.js'
import {apiUrl} from "@/utils/main";

export default {
    name: 'MaintenanceSetting',
    mixins: [mixins],
    props: { //一個元件需要明確地宣告它所接受的props，這樣Vue 才能知道外部傳入的哪些是props
        MaintenanceData: {
            require: false,
            default: undefined,
            type: Object
        },
        dialogMaintenanceVisible: {
            require: true,
            default: undefined,
            type: Boolean
        }
    },
    data() {
        return {
            isModify: false,
            updateQuery: {
                id: undefined,
                title: undefined,
                description: undefined,
                maintain: undefined,
                dateRange: [],
            },
            rules: {
                title: [{ required: true, message: '(必填)', trigger: 'blur' }],
                description: [{ required: true, message: '(必填)', trigger: 'blur' }],
                maintain: [{ required: true, message: '(必填)', trigger: 'blur' }],
                dateRange: [{ type: 'array', required: true, message: '請選擇日期區間', trigger: 'blur', len:2 }]
            }
        };
    },
    watch: {
        MaintenanceData(val) {
            if (val) {
                this.isModify = true
                this.updateQuery.id = val.id
                this.updateQuery.title = val.title
                this.updateQuery.description = val.description
                this.updateQuery.maintain = val.maintain
                this.updateQuery.dateRange.push(val.publishStart)
                this.updateQuery.dateRange.push(val.publishEnd)
            }
        },
    },
    methods: {
        dialogMaintenanceOnClose() {
            this.resetUpdateQuery()
            this.$refs.dataForm.resetFields() // 表單校驗結果重置
            this.$emit('close') // 觸發父層 close
        },
        updateMaintenance() {
            this.$refs['dataForm'].validate((valid) => {
                if (valid) {
                    this.axios.put('/api/v1/maintenance', this.updateQuery).then(() => {
                        this.$message({
                            showClose: true,
                            message: '修改成功',
                            type: 'success'
                        })
                        this.dialogMaintenanceOnClose()
                    }).catch(err => {
                        this.$message({
                            message: '修改失敗 '+err.data,
                            type: 'error'
                        })
                    })
                } else {
                    this.$message({
                        message: '輸入欄位格式不正確',
                        type: 'error'
                    })
                }
            })
        },
        resetUpdateQuery() {
            this.isModify = false
            this.dateRange = []
            this.updateQuery = {
                id: undefined,
                title: undefined,
                description: undefined,
                maintain: undefined,
                dateRange: []
            }
        }
    }
}
</script>
