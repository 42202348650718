<template>
  <div >
    <el-row>
      <el-button style="float: left" size="mini" type="primary" icon="el-icon-plus" @click="handleMaintenanceAdd" v-if="this.$store.state.currentAccount.roleInfo.roleData[0].name=='SUPERADMIN'">
        {{$t('Add')}}
      </el-button>
      <div style="float: right;">
          <el-input size="small" v-model="listQuery.title" v-bind:placeholder="$t('Title')" class="filter-item" clearable />
      </div>
    </el-row>
    <el-table
      :header-cell-style="{color:'#20638C'}"
      v-loading="listLoading"
      :data="list" style="width: 100%;"
      :cell-style="{padding: '0', height: '40px'}"
      @sort-change="tableSortChange"
     >
      <el-table-column align="center" prop="id" v-bind:label="$t('Number')" min-width="35%" sortable />
      <el-table-column align="center" prop="maintain" v-bind:label="$t('Maintenance')+$t('Date')" sortable />
      <el-table-column align="center" prop="title" v-bind:label="$t('Title')" min-width="150%" />
      <el-table-column align="center" prop="publishStart" v-bind:label="$t('Publish')+$t('Start')" sortable />
      <el-table-column align="center" prop="publishEnd" v-bind:label="$t('Publish')+$t('End')" sortable />
      <el-table-column align="center" :label="$t('Operate')" width="130px" >
        <template slot-scope="scope">
           <el-button type="success" :title="$t('Edit')" size="mini" icon="el-icon-setting" @click="showMaintenanceSetting(scope.row)"/>
           <el-button type="danger" :title="$t('Delete')" size="mini" icon="el-icon-delete" @click="handleMaintenanceDelete(scope.$index, scope.row)" />
        </template>
      </el-table-column>
    </el-table>
    <!--分頁組件-->
    <pagination v-show="total>0" :total="total" :current.sync="paginationSelectedPage" :size.sync="listQuery.size" @pagination="getList" />
    <MaintenanceSetting :dialogMaintenanceVisible="dialogMaintenanceSettingVisible" :MaintenanceData="MaintenanceData" @close="dialogMaintenanceSettingOnClose()" />
  </div>
</template>

<script>
import Pagination from '@/components/Pagination'
import { mixins } from '@/views/common/mixins.js'
import MaintenanceSetting from './MaintenanceSetting.vue'

export default {
  name: 'Maintenance',
  components: {
    Pagination,
    MaintenanceSetting,
  },
  mixins: [mixins],
  data() {
    return {
      result: '',
      dialogWidth: 0,
      total: 0,
      list: [],
      serviceList: [],
      listLoading: false,
      listQuery: {
        title: '',
        page: 0, // jpa pageIndex起始從0開始，但Pagination從1開始，透過watch paginationSelectedPage同步
        size: 15,
        sort: 'id,Desc'
      },
      paginationSelectedPage: 1, // 需配合Pagination元件與listQuery使用
      MaintenanceData: undefined, // 維護訊息
      dialogMaintenanceSettingVisible: false,
    };
  },
  watch: {
    paginationSelectedPage: {
      handler: function(newValue) {
        this.listQuery.page = newValue - 1
      }
    },
    'listQuery.title': function() {
      this.listQuery.page = 0
      this.resetPage(this.getList)
    },
  },
  created() {
    this.dialogWidth = this.setDialogWidth()
    this.resetPage(this.getList)
  },
  mounted() {
    window.onresize = () => {
      return (() => {
        this.dialogWidth = this.setDialogWidth()
      })()
    }
  },
  methods: {
    getList() {
      this.listLoading = true
      this.axios.get('/api/v1/maintenance', {params: this.listQuery}).then(res=> {
        this.list = res.data.content
        this.total = res.data.totalElements
        this.listLoading = false
      })
    },
    handleMaintenanceAdd() {
      this.dialogMaintenanceSettingVisible = true
    },
    showMaintenanceSetting(row) {
      this.dialogMaintenanceSettingVisible = true
      this.MaintenanceData = row
    },
    handleMaintenanceDelete(index, row) {
      this.$confirm(this.$t('Hint'), {
        message: this.$t('Confirm')+' '+this.$t('Delete')+' - ' + row.title + ' ?',
        confirmButtonText: this.$t('Confirm'),
        cancelButtonText: this.$t('Cancel'),
        type: 'warning'
      }).then(() => {
        this.delMaintenance(index, row.id)
      }).catch(()=> {
        console.log('cancel delete Maintenance')
      })
    },
    async delMaintenance(index, id) {
      this.axios.delete('/api/v1/maintenance/' + id).then(()=> {
        this.$delete(this.list, index);
        this.$message({showClose: true,message: this.$t('Delete')+this.$t('Success'),type: 'success'})
      }).catch(err => {
        this.$message({message: this.$t('Delete')+this.$t('Fail')+" "+err.data,type: 'error'})
      })
    },
    dialogMaintenanceSettingOnClose() {
      this.dialogMaintenanceSettingVisible = false
      this.MaintenanceData = undefined
      this.resetPage(this.getList)
    },
  }
}
</script>
